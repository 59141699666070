/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages


        function xy() {
          var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            x = w.innerWidth || e.clientWidth || g.clientWidth,
            y = w.innerHeight || e.clientHeight || g.clientHeight;
          return {x: x, y: y};
        }

        // related product slider
        var $homeSlider = $('.frontpage-gallery-related');
        $homeSlider.owlCarousel({
          items: 1,
          loop: true,
          stagePadding: 0,
          margin: 0,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          freeDrag: false,
          animateOut: 'fadeOut',
          smartSpeed: 1500,
          autoplay: true,
          autoplayTimeout: 8000,
          autoplayHoverPause: false,
        });

        // mobile menu icon
        $('#nav-icon').click(function () {
          $(this).toggleClass('open');
        });

        // fancybox init
        $('.fancybox').fancybox({
          arrows: true,
          buttons: ['zoom', 'close']
        });

        // contact form
        $('.newsletter').submit(function (e) {
          e.preventDefault();

          var $form = $(this),
            form_data = new FormData($(this)[0]),
            $notice_container = $(this).find('.newsletter_notice'),
            $notice_container_msg = $(this).find('.newsletter_notice_message'),
            $notice_container_close = $(this).find('.newsletter_notice_close');

          if ($form.hasClass('submitting')) {
            return false;
          }
          $form.addClass('submitting');
          $notice_container.stop().slideUp(300);

          $.ajax({
            method: 'POST',
            url: localized_variables.ajax_url,
            data: form_data,
            contentType: false,
            dataType: 'json',
            processData: false,
            success: function (data) {
              $notice_container_close.addClass(data.status);
              $notice_container_msg.html(data.message).removeClass('alert-danger alert-success').addClass('alert alert-' + data.status);
              setTimeout(function () {
                $notice_container.stop().slideDown(300, function () {
                  $form.removeClass('submitting');
                });
              }, 100);
              if (data.status === 'success') {
                $(this).find('.newsletter_inputs').animate({
                  opacity: 0,
                  height: 0
                }, 600);
              }
            }
          });
        });
        // work form notice closer
        $('.newsletter_notice_close').on('click', function () {
          $(this).parent().animate({
            opacity: 0,
            height: 0
          }, 400, function () {
            $(this).css('display', 'none').css('opacity', 1).css('height', 'auto');
          });

        });

        // set all section elements with .hideme class
        $('main.main > section').each(function () {
          $(this).addClass('hideme');
        });

        $('.second-section-img-list').each(function () {
          $(this).addClass('hideme-img');
        });

        function showme() {
          // check the location of each desired element
          $('.hideme').each(function (i) {
            var bottom_of_object = $(this).position().top;
            var bottom_of_window = $(window).scrollTop() + ($(window).height() - 300);
            // if the object is completely visible in the window, fade it
            if (bottom_of_window > bottom_of_object) {
              $(this).animate({'opacity': '1'}, 800);
            }
          });
        }

        showme();
        $(window).scroll(function () {
          showme();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        // show/hide navbar menu
        /*function show_hide_navbar() {
          var breakpoint_h = 60,
            $win_scroll = $(window).scrollTop(),
            $navbar = $('.navbar-fixed-top');
          if ($win_scroll > breakpoint_h) {
            if (!$navbar.hasClass('scrolled')) {
              $navbar.addClass('scrolled');
            }
          } else {
            if ($navbar.hasClass('scrolled')) {
              $navbar.removeClass('scrolled');
            }
          }
        }

        show_hide_navbar();
        $(window).scroll(function () {
          show_hide_navbar();
        });*/

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Single product
    'product_template_default': {
      init: function () {
        $('.product-header img[id^="transition-image-"]').on('click', function () {
          $('#firstProdImage').click();
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'page_template_template_contacts': {
      init: function () {

        // contact form

        $('.contact_form').submit(function (e) {
          e.preventDefault();

          var $form = $(this),
            form_data = new FormData($(this)[0]),
            $notice_container = $(this).find('.contact_form_notice'),
            $notice_container_close = $(this).find('.contact_form_notice_close'),
            $notice_container_msg = $(this).find('.contact_form_notice_message');

          if ($form.hasClass('submitting')) {
            return false;
          }
          $form.addClass('submitting');
          $notice_container.stop().slideUp(300);

          $.ajax({
            method: 'POST',
            url: localized_variables.ajax_url,
            data: form_data,
            contentType: false,
            dataType: 'json',
            processData: false,
            success: function (data) {
              $notice_container_close.addClass(data.status);
              $notice_container_msg.html(data.message).removeClass('alert-danger alert-success').addClass('alert alert-' + data.status);
              setTimeout(function () {
                $notice_container.stop().slideDown(300, function () {
                  $form.removeClass('submitting');
                });
              }, 100);
              if (data.status === 'success') {
                $(this).find('.contact_form_inputs').animate({
                  opacity: 0,
                  height: 0
                }, 600);
              }
            }
          });
        });

        $('.contact_form_notice_close').on('click', function () {
          $(this).parent().animate({
            opacity: 0,
            height: 0
          }, 400, function () {
            $(this).css('display', 'none').css('opacity', 1).css('height', 'auto');
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
